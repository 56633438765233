<template>
  <main-layout>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          lg="10"
        >
          <section>
            <p>
              Dokter Michel Robyns is huisarts in Tervuren. U vindt hier alle informatie over de huisartsenpraktijk,
              de openingstijden van de praktijk, informatie over de wachtdienst, het Globaal Medisch Dossier,
              vaccinaties enzovoort.
            </p>
          </section>

          <h3 class="headline pb-3">Raadpleging</h3>
          <base-divider
            color="accent"
            dense
          />
          <p>De praktijk werkt <b>uitsluitend op afspraak</b>.<br>
            Hierdoor kunnen wachttijden beperkt worden.
            Door enkel op afspraak te werken krijgt u de best mogelijke zorg.</p>

          <h3 class="headline pb-3">Afspraken</h3>

          <base-divider
            color="accent"
            dense
          />
          <p>
            Voor afspraken kan u telefonisch contact opnemen
            op het nummer <b> 02 767 40 18 </b> liefst tussen <nobr><b>8u - 9u 30</b></nobr>.
          </p>
          <p>
            Gelieve uw identiteitskaart (eID of eventueel Kids-ID) mee te nemen.<br>
            Indien u komt voor een bloedafname neem dan ook een vignetje van de mutualiteit mee.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </main-layout>

</template>

<script>
  import MainLayout from '@/layouts/Main.vue'
  import BaseDivider from '@/components/Divider.vue'

  export default {
    components: {
      MainLayout,
      BaseDivider
    },
    head () {
      return {title: this.$t('home')}
    },
    methods: {
      getName(name) {
        return (this.$vuetify.breakpoint.xs) ? name.slice(0, 2) : name
      }
    }
  }
</script>

<style lang="scss" scoped>
  @media (max-width: 600px) {
    table.table-consultation tbody tr {
      td, th {
        padding: 12px;
      }
      th:first-child {
        padding: 12px !important;
      }
    }
  }
</style>
